import React from "react"
import Message from "../components/subscriber/message/message"
import Hero from "../sections/hero/hero"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import freeQueries from "../utils/free-app-idea-queries"
import Layout from "../components/Layout"
import Subscriber from "../sections/subscriber/subscriber_section"
import ebook from "../images/ebook.png"
import { getSeo } from "../utils/seo"

export default function FreeAppIdeaGuide() {
  const { hero } = freeQueries()
  const content = {
    title: "Get your free guide to coming up with an App idea!",
    description:
      "To access this key checklist for building out your app idea, please register for our newsletter by adding your details below:",
    url: "/thanks-for-subscribing-ebook",
    image: ebook,
  }
  return (
    <Layout>
      <Hero hero={hero} styles={hero_styles} nextSection={"message"} />
      <Subscriber content={content} />
    </Layout>
  )
}

export const Head = () => {
  const { seo } = freeQueries()
  return getSeo(seo)
}
